@import url("https://fonts.googleapis.com/css?family=Lato:400,700,900|Montserrat:700");
:root { 
  --blue: #3068d9;
  --alertRed: #f94c53;
  --grey: #7b7777;
}

* {
  box-sizing: border-box;
  font-family: "Lato", "Helvetica";
  margin: 0;
  padding: 0;
 
}
html, body {
  width: 100%;
}